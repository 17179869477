import {ClientStatus} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {TTimestamp} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {getFormattedStartTime} from '@chancer/common/lib/utils/DateUtils';
import React, {useEffect, useMemo, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {platformValue} from '../PlatformUtils';
import {COLOR_LIGHT, FONT_COPY} from '../Styles/DesignSystem-chancer';
import {CompositedTextStyle} from '../Styles/StyleTypes';
import {ClockIcon} from '../Svg/SvgIcons';
import {LabelBadge} from './LabelBadge';

interface IProps {
  startTime: TTimestamp;
  status: ClientStatus;
  questionsRemaining?: number;
  questionsInPlay?: boolean;
  numTimeUnits?: number;
  featured?: boolean;
  compressedFormat?: boolean;
  showDurationFromMs?: number;
  showClock?: boolean;
  textStatusColorOverride?: string;
  isLive?: boolean;
}

export const CompStatus: React.FC<IProps> = (props) => {
  const getContent = (starts: TTimestamp, status: ClientStatus) => {
    if (
      props.isLive === true ||
      (props.isLive === undefined &&
        (status === ClientStatus.IN_PLAY || props.questionsInPlay === true))
    ) {
      return <LabelBadge label="LIVE" />;
    } else if (status === ClientStatus.OPEN) {
      return (
        <CountDown
          starts={starts.toDate()}
          numTimeUnits={props.numTimeUnits || 2}
          featured={props.featured === true}
          showDurationFromMs={props.showDurationFromMs}
          compressedFormat={props.compressedFormat}
          showClock={props.showClock}
          textStatusColorOverride={props.textStatusColorOverride}
        />
      );
    } else {
      return (
        <TextStatus
          status={status}
          isLive={props.isLive}
          featured={props.featured === true}
          textStatusColorOverride={props.textStatusColorOverride}
        />
      );
    }
  };

  return <View>{getContent(props.startTime, props.status)}</View>;
};

interface ICountDownProps {
  starts: Date;
  numTimeUnits: number;
  featured: boolean;
  showDurationFromMs?: number;
  compressedFormat?: boolean;
  showClock?: boolean;
  textStatusColorOverride?: string;
}

const CountDown: React.FC<ICountDownProps> = (props) => {
  const starts = props.starts;
  const msRemaining = starts.valueOf() - new Date().valueOf();
  const numUnits = props.numTimeUnits || 2;
  const showDurationFromMs = props.showDurationFromMs ?? -1;
  const compressedFormat = props.compressedFormat ?? false;

  const contextualStatusText: CompositedTextStyle = useMemo(
    () => [
      styles.statusText,
      props.featured ? styles.statusTextPadding : undefined,
      props.textStatusColorOverride
        ? {color: props.textStatusColorOverride}
        : undefined,
    ],
    [props.featured, props.textStatusColorOverride],
  );
  const [timeLeft, setTimeLeft] = useState(
    getFormattedStartTime(starts, {
      numUnits,
      compressedFormat,
      showDurationFromMs,
    }),
  );

  useEffect(() => {
    if (msRemaining > 0) {
      if (timeLeft.nextIntervalMs > 0) {
        let timeout = setTimeout(
          () => {
            setTimeLeft(
              getFormattedStartTime(starts, {
                numUnits,
                compressedFormat,
                showDurationFromMs,
              }),
            );
          },
          platformValue(timeLeft.nextIntervalMs, {
            android: Math.min(60000, timeLeft.nextIntervalMs),
          }),
        );
        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [
    timeLeft,
    msRemaining,
    starts,
    numUnits,
    compressedFormat,
    showDurationFromMs,
  ]);

  let copy: string;
  if (msRemaining > 0) {
    copy = `${timeLeft.text}`;
  } else {
    copy = props.featured === true ? 'Starting Soon' : '';
  }

  return (
    <View style={styles.statusContainer}>
      {(props.featured || props.showClock) && (
        <ClockIcon
          color={props.textStatusColorOverride ?? COLOR_LIGHT}
          width={13}
          height={13}
        />
      )}
      <Text style={contextualStatusText}>{copy}</Text>
    </View>
  );
};

interface ITextStatusProps {
  status: ClientStatus;
  isLive: boolean | undefined;
  featured: boolean;
  showClock?: boolean;
  textStatusColorOverride?: string;
}

const TextStatus: React.FC<ITextStatusProps> = (props) => {
  const status = props.status;
  const contextualStatusText: CompositedTextStyle = useMemo(
    () => [
      styles.statusText,
      props.featured ? styles.statusTextPadding : undefined,
      props.textStatusColorOverride
        ? {color: props.textStatusColorOverride}
        : undefined,
    ],
    [props.featured, props.textStatusColorOverride],
  );

  let copy: string;
  if (props.isLive === false) {
    // Game that has live stream but has not yet started
    copy = 'Starting Soon';
  } else if (status === ClientStatus.IN_PLAY) {
    copy = 'In progress';
  } else if (status === ClientStatus.RESULTED) {
    copy = props.featured ? 'Verifying entries' : 'Verifying';
  } else if (status === ClientStatus.VERIFIED) {
    copy = 'Finished';
  } else if (status === ClientStatus.PRE_START) {
    copy = props.featured ? 'Starting Soon' : 'Starting';
  } else if (status === ClientStatus.SUSPENDED) {
    copy = 'Suspended';
  } else {
    copy = 'Unknown';
  }

  return (
    <View style={styles.statusContainer}>
      {(props.featured || props.showClock) && (
        <ClockIcon
          color={props.textStatusColorOverride ?? COLOR_LIGHT}
          width={13}
          height={13}
        />
      )}
      <Text style={contextualStatusText}>{copy}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  statusContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    opacity: 0.8,
  },
  statusText: {
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY,
    fontSize: 14,
  },
  statusTextPadding: {
    paddingLeft: 4,
  },
});
