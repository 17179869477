import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {Observable, Subscription} from 'rxjs';

import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {TMultiGameLeader} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {
  IColorConfiguration,
  getColorConfiguration,
} from '@chancer/common/lib/utils/ColorUtils';
import {LeaderboardAvatar} from '../Avatar/LeaderboardAvatar';
import {LabelBadge} from '../Status/LabelBadge';
import {
  COLOR_DARK,
  COLOR_GREEN,
  COLOR_GREY_6,
  COLOR_LIGHT,
  COLOR_RED,
  COLOR_YELLOW,
  FONT_COPY_BOLD,
  FONT_COPY_MEDIUM,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';
import {
  AchievementWinnerIcon,
  LeaderDownArrowIcon,
  LeaderUpArrowIcon,
} from '../Svg/SvgIcons';
import {getTagline} from './EntrantItem';
import {MultiGameScore} from './MultiGameScore';
import {WinnerEntrantItemWrapper} from './WinnerEntrantItemWrapper';

export interface IMultiGameEntrantItemProps {
  visible?: boolean;
  position: number;
  player: TMultiGameLeader;
  winner: boolean;
  currentUser: TFirebaseUser | null;
  isFollowing: boolean;
  average?: number;
  colorConfig?: IColorConfiguration;
  getUserById: (userId: string) => Observable<TFirebaseUser>;
  onPress?: (user: TFirebaseUser) => void;
}

export const ITEM_HEIGHT = 64;

export const MultiGameEntrantItem: React.FC<IMultiGameEntrantItemProps> = (
  props,
) => {
  const {
    getUserById,
    colorConfig = getColorConfiguration(undefined),
    onPress,
  } = props;
  const visible = props.visible ?? true;
  const userId = props.player.u;
  const isCurrentUser = props.currentUser !== null;

  const [user, setUser] = useState<TFirebaseUser | null>(props.currentUser);

  // Deal with other users
  useEffect(() => {
    let sub: Subscription | undefined;
    if (visible && user === null) {
      sub = getUserById(userId).subscribe({
        next: (u) => {
          setUser(u);
        },
      });
    }
    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [visible, userId, user, getUserById]);

  const contextualContainer: CompositedViewStyle = useMemo(
    () => [
      styles.container,
      isCurrentUser
        ? {backgroundColor: colorConfig.leaderboardUsersItemBackground}
        : {backgroundColor: colorConfig.leaderboardItemsBackground},
    ],
    [colorConfig, isCurrentUser],
  );

  const _onPress = useCallback(() => {
    if (user !== null && onPress) {
      onPress(user);
    }
  }, [user, onPress]);

  return (
    <WinnerEntrantItemWrapper isWinner={props.winner}>
      <Pressable
        style={contextualContainer}
        disabled={onPress === undefined}
        onPress={_onPress}>
        {visible && (
          <>
            <View style={styles.positionContainer}>
              <Text style={styles.position}>{props.position}</Text>
              <View style={styles.arrowContainer}>
                {props.player.d !== undefined && props.player.d > 0 && (
                  <LeaderUpArrowIcon
                    color={COLOR_GREEN}
                    width={10}
                    height={14}
                  />
                )}
                {props.player.d !== undefined && props.player.d < 0 && (
                  <LeaderDownArrowIcon
                    color={COLOR_RED}
                    width={10}
                    height={14}
                  />
                )}
              </View>
            </View>
            <LeaderboardAvatar
              user={user}
              isFollowing={props.isFollowing}
              isCurrentUser={isCurrentUser}
            />
            <View style={styles.copyContainer}>
              <View style={styles.nameContainer}>
                <Text
                  style={styles.name}
                  numberOfLines={1}
                  ellipsizeMode="tail">
                  {user?.name}
                </Text>
                {isCurrentUser && (
                  <LabelBadge
                    labelColor={COLOR_DARK}
                    backgroundColor={COLOR_YELLOW}
                    label="YOU"
                    style={styles.currentUserBadge}
                  />
                )}
                {props.player.f !== undefined && props.player.f > 0 && (
                  <>
                    <View style={styles.achievementIcon}>
                      <AchievementWinnerIcon
                        color={COLOR_GREY_6}
                        width={12}
                        height={14}
                      />
                    </View>
                    <Text style={styles.achievementValue}>
                      {props.player.f}
                    </Text>
                  </>
                )}
                {props.average !== undefined && (
                  <>
                    <Text style={styles.achievementLabel}>Avg</Text>
                    <Text style={styles.achievementValue}>{props.average}</Text>
                  </>
                )}
              </View>
              <Text
                style={styles.tagline}
                numberOfLines={1}
                ellipsizeMode="tail">
                {getTagline(user)}
              </Text>
            </View>
            <View style={styles.multiGameScoreContainer}>
              <MultiGameScore score={props.player.s} />
            </View>
          </>
        )}
      </Pressable>
    </WinnerEntrantItemWrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    userSelect: 'none',
    position: 'relative',
    height: 64,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 8,
    marginTop: 2,
  },
  positionContainer: {
    marginTop: 13,
    marginBottom: 16,
    alignSelf: 'flex-start',
  },
  position: {
    flexShrink: 0,
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY_BOLD,
    fontSize: 16,
    width: 36,
    textAlign: 'center',
    alignSelf: 'flex-start',
  },
  arrowContainer: {
    width: 36,
    alignItems: 'center',
  },
  copyContainer: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    alignSelf: 'flex-start',
    marginLeft: 12,
    overflow: 'hidden',
  },
  nameContainer: {
    flexDirection: 'row',
    marginTop: 13,
    overflow: 'hidden',
    alignItems: 'center',
  },
  name: {
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY_BOLD,
    fontSize: 16,
    marginRight: 8,
  },
  currentUserBadge: {alignSelf: 'flex-end', marginRight: 8, marginBottom: 1},
  achievementIcon: {
    marginTop: 2,
    marginLeft: 6,
  },
  achievementLabel: {
    marginLeft: 6,
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 14,
    color: COLOR_GREY_6,
  },
  achievementValue: {
    flexGrow: 0,
    marginLeft: 2,
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 14,
    color: COLOR_GREY_6,
  },
  tagline: {
    color: COLOR_GREY_6,
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 14,
    marginRight: 4,
  },
  multiGameScoreContainer: {
    flexShrink: 0,
    textAlign: 'center',
    marginTop: 13,
    alignSelf: 'flex-start',
  },
});
